<template>
  <bulk-edit-wrapper
    :data="data"
    title="一括で利用者の所属グループを編集します"
    tab="units"
    :searchCondition="{ showConditionUnits: true }"
    @update:checkFlags="updateCheckFlags"
  >
    <template v-slot:process>
      <p>
        <span>
          {{ `以上${countCheckFlags()}名を` }}
        </span>
        <span>
          <ul>
            <li>
              <input
                type="radio"
                id="unit_belongs_on"
                v-model="unitBelongsChangeType"
                value="1"
              >
              <label for="unit_belongs_on">以下のグループに所属させる（選択で所属、未選択で解除）</label>
              <div :class="$style.unit_belongs_box">
                <ul>
                  <li
                    v-for="unit in units"
                    :key="unit.id"
                  >
                    <input
                      :id="unit.id"
                      type="checkbox"
                      :disabled="unitBelongsChangeType !== '1'"
                      :value="unit.id"
                      v-model="selectedUnitIds"
                    >
                    <label :for="unit.id">{{ unit.name }}</label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <input
                type="radio"
                id="unit_belongs_off"
                v-model="unitBelongsChangeType"
                value="0"
              >
              <label for="unit_belongs_off">すべてのグループから所属解除する</label>
            </li>
          </ul>
        </span>
      </p>
      <div :class="$style.bulk_edit_unit_belongs_buttons">
        <btn-container>
          <basic-btn
            tag="button"
            :disabled="!canSave"
            v-on:click="save"
          >確定</basic-btn>
          <spacer :x="2" />
          <basic-btn tag="button" type="bdr" v-on:click="hideModal">キャンセル</basic-btn>
        </btn-container>
      </div>
    </template>
  </bulk-edit-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import BulkEditWrapper from '@/views/components/modal/contents/parts/BulkEditWrapper.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';

export default {
  name: 'bulk-edit-unit-belongs',
  components: {
    BulkEditWrapper,
    BasicBtn,
    BtnContainer,
  },
  props: ['data'],
  data() {
    return {
      checkFlags: {}, // key=user_id, value=boolean(チェック状態)
      targetSchoolYear: null,
      units: [], // グループ一覧
      selectedUnitIds: [], // 選択されたグループID
      unitBelongsChangeType: '1', // 所属させる/解除
    };
  },
  computed: {
    ...mapState(['user', 'modal']),
    targetSchoolId() {
      if (this.data.account_type === 31) {
        // 事務局の場合は選択した学校のグループを取得する
        return this.data._targetSchool.id;
      }
      if (this.data.account_type === 11) {
        // 学校管理者の場合は自分の学校のグループを取得する
        return this.data.school[0].id;
      }
      // その他の場合はnull
      return null;
    },
    canSave() {
      // ユーザーが選択されていない場合は非活性
      if (this.countCheckFlags() === 0) {
        return false;
      }
      // 「すべてのグループから所属解除する」選択時はボタン活性化
      if (this.unitBelongsChangeType === '0') {
        return true;
      }
      // 「以下のグループに所属させる」選択時は選択されたグループが1つ以上あればボタン活性化
      return this.selectedUnitIds.length > 0;
    },
  },
  created() {
    this.getUnits();
  },
  methods: {
    // グループ一覧取得
    async getUnits() {
      const params = {
        school_id: this.targetSchoolId,
      };
      const response = await this.axios({
        method: 'GET',
        url: '/v1/unit/get/list',
        params,
      });
      const results = response.data.units.data;
      this.units = results.filter((unit) => unit.flag !== 999); // 非アクティブは除外
    },

    // 子コンポーネントからのチェックフラグの更新
    updateCheckFlags(checkFlags) {
      this.checkFlags = checkFlags;
    },

    // 一括で変更する人数を集計
    countCheckFlags() {
      const list = Object.keys(this.checkFlags).filter((elem) => this.checkFlags[elem]);
      return list.length;
    },

    /**
     * 学年の登録以外にも非アクティブも一括で可能になっている
     * 非アクティブの際も998はtargetSchoolYearに入ってくるので値で判定
     */
    async save() {
      // コールバック関数の特定
      const callbackKey = 'from_units_after_update';
      const callback = this.modal.contents.callback[callbackKey] || (() => {});
      
      const isBelongMode = this.unitBelongsChangeType === '1';
      const userCount = this.countCheckFlags();
      const selectedUnits = this.units.filter((unit) => this.selectedUnitIds.includes(unit.id));
      const confirmMsg = isBelongMode
        ? `選択された${userCount}名を以下のグループに所属させます。\n${(selectedUnits.map((unit) => `・${unit.name}`)).join('\n')}`
        : `選択された${userCount}名をすべてのグループから所属解除します。`;

      if (!confirm(confirmMsg)) return; // キャンセルは即時中断

      const targetUserIds = Object.keys(this.checkFlags).filter((elem) => this.checkFlags[elem]);
      if (isBelongMode) {
        // 所属させる
        await this.setBlongs(targetUserIds, selectedUnits);
      } else {
        // 所属の解除
        await this.removeBelongs(targetUserIds);
      }
      // 呼び出し元に伝播してグループ情報を再取得する
      callback();
      alert(`${userCount}名の所属を更新しました`);
      this.hideModal();
    },

    // 所属させる
    async setBlongs(targetUserIds, selectedUnits) {
      // 対象ユーザの所属をいったん全解除する
      try {
        await this.__removeBelongs(targetUserIds);
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.log(error);
        alert('所属登録前の所属解除に失敗しました');
        throw error;
      }

      // 所属を追加
      const promises = selectedUnits.map((unit) => {
        const data = {
          school_id: unit.school_id, // 変更なし
          id: unit.id, // 変更なし
          label: unit.label, // 変更なし
          name: unit.name, // 変更なし
          update_user_id: this.user.id,
          newUsers: targetUserIds.map((userId) => ({ id: userId })),
        };
        return this.axios({
          method: 'POST',
          url: '/v1/unit/set/updater',
          data,
        });
      });
      try {
        await Promise.all(promises);
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.log(error);
        alert('所属の更新に失敗しました');
        throw error;
      }
    },

    // 所属解除
    async removeBelongs(targetUserIds) {
      try {
        await this.__removeBelongs(targetUserIds);
      } catch (error) {
        if (error.message) console.log(error.message);
        else console.log(error);
        alert('所属の解除に失敗しました');
        throw error;
      }
    },
    async __removeBelongs(targetUserIds) {
      // 選択したユーザ*取得したUnitの組み合わせで削除
      const delKeys = (() => {
        const keys = [];
        targetUserIds.forEach((userId) => {
          this.units.forEach((unit) => {
            keys.push({
              user_id: userId,
              unit_id: unit.id,
              ignore_not_exists: true, // 存在しない場合は無視
            });
          });
        });
        return keys;
      })();
      const promises = delKeys.map((key) => this.axios({
        method: 'POST',
        url: '/v1/unit/set/deleter',
        params: key,
      }));

      return Promise.all(promises);
    },

    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.batch_chenge_school_year {
  display: flex;
  &_buttons {
    margin-left: auto;
  }
}
.unit_belongs_box {
  margin-left: 2rem;
}
.bulk_edit_unit_belongs_buttons {
  margin-top: auto;
  margin-left: auto;
}
</style>
