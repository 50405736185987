<template>
  <transition name="fade" mode="out-in">
    <div class="modal contents" v-if="isShow">
      <div class="modal__bg" v-on:click="hideModal" :class="$style.wrap"></div>

      <div
        class="modal__content"
        :class="[
          $style.modal,
          isUserChartWho5 ? $style.UserChartWho5 : null,
        ]"
      >
        <!-- modalNameに応じて表示するコンポーネントを変化 -->
        <component
          v-bind:is="contents.modalName"
          v-bind:data="contents.data" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
// モーダルコンポーネントの登録
import reserveCancel from './contents/ReserveCancel.vue';
import DailySchedule from './contents/DailySchedule.vue';
import EditSchedule from './contents/EditSchedule.vue';
import BulkEditSchoolYear from './contents/BulkEditSchoolYear.vue';
import BulkEditUnitBelongs from './contents/BulkEditUnitBelongs.vue';
import InputVcid from './contents/InputVCID.vue';
import UserChartWho5 from './contents/UserChartWho5.vue';
import RegisterMonitoringSchedule from './contents/RegisterMonitoringSchedule.vue';
import ListMonitoringSchedule from './contents/ListMonitoringSchedule.vue';

export default {
  name: 'ContentsModal',
  components: {
    reserveCancel,
    DailySchedule,
    EditSchedule,
    BulkEditSchoolYear,
    BulkEditUnitBelongs,
    InputVcid,
    UserChartWho5,
    RegisterMonitoringSchedule,
    ListMonitoringSchedule,
  },
  computed: {
    ...mapState('modal', ['contents']),
    isShow() {
      return this.contents.modalName !== '';
    },
    isUserChartWho5() {
      return this.contents.modalName === 'user-chart-who5';
    },
  },
  methods: {
    hideModal(args = null) {
      this.$store.dispatch('modal/contents/hideModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 1;
}
.modal {
  position: relative;
  z-index: 5;
  padding: 40px;
  width: 80%;
  max-height: 70%;
  position: fixed;
  top: calc(50% + var(--header-height) / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
  @include sm-view {
    padding: 20px;
    max-height: calc(70% - 69px);
    top: 50%;
  }

  /* スクロールバーのスタイル調整 */
  &::-webkit-scrollbar {
    width: 10px; // スクロールバーの幅
  }
  /* スクロールバーのトラック（背景） */
  &::-webkit-scrollbar-track {
    border-radius: 10px; // 角丸
  }
  /* スクロールバーのつまみ */
  &::-webkit-scrollbar-thumb {
    background: var(--gray-main); // 色
    border-radius: 10px; // 角丸
  }

  &.UserChartWho5 { // ユーザーのWHO-5チャート表示用（縦スクロールバーを表示しないための対処）
    min-height: 780px;
    @include sm-view {
      min-height: inherit;
    }
  }
}
</style>
