<template>
  <bulk-edit-wrapper
    :data="data"
    tab="students"
    @update:checkFlags="updateCheckFlags"
  >
    <template v-slot:process>
      <p>
        <span>
          {{ `以上${countCheckFlags()}名${targetSchoolYear !== 998 ? 'の学年' : ''}を` }}
        </span>
        <span>
          <select
            :class="$style.select_box"
            v-model="targetSchoolYear"
            name="targetSchoolYear"
            id="targetSchoolYear">
            <option :value="null">選択</option>
            <option
              v-for="n of 6"
              :key="n"
              :value="n">{{ n }}</option>
            <option
              :value="998">非アクティブ</option>
          </select>
        </span>
        <span v-if="targetSchoolYear">{{ `${targetSchoolYear !== 998 ? '年生' : ''}` }}</span>
        <span>へ変更</span>
      </p>
      <div :class="$style.batch_chenge_school_year_buttons">
        <btn-container>
          <basic-btn :disabled="!targetSchoolYear" tag="button" v-on:click="save">確定</basic-btn>
          <spacer :x="2" />
          <basic-btn tag="button" type="bdr" v-on:click="hideModal">キャンセル</basic-btn>
        </btn-container>
      </div>
    </template>
  </bulk-edit-wrapper>
</template>

<script>
import BulkEditWrapper from '@/views/components/modal/contents/parts/BulkEditWrapper.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';

export default {
  name: 'bulk-edit-school-year',
  components: {
    BulkEditWrapper,
    BasicBtn,
    BtnContainer,
  },
  props: ['data'],
  data() {
    return {
      checkFlags: {}, // key=user_id, value=boolean(チェック状態)
      targetSchoolYear: null,
    };
  },
  methods: {
    // 子コンポーネントからのチェックフラグの更新
    updateCheckFlags(checkFlags) {
      this.checkFlags = checkFlags;
    },

    // 一括で変更する人数を集計
    countCheckFlags() {
      const list = Object.keys(this.checkFlags).filter((elem) => this.checkFlags[elem]);
      return list.length;
    },

    /**
     * 学年の登録以外にも非アクティブも一括で可能になっている
     * 非アクティブの際も998はtargetSchoolYearに入ってくるので値で判定
     */
    async save() {
      // 学年を変更するリストを作成
      const strList = Object.keys(this.checkFlags).filter((elem) => this.checkFlags[elem]);
      const target = strList.map((elem) => Number(elem));

      // 選択確認
      if (target.length <= 0) {
        alert('学年を変更する生徒を選択してください');
        return true;
      }
      if (!this.targetSchoolYear) {
        alert('変更後の学年を選択してください');
        return true;
      }

      let confirmation;
      if (this.targetSchoolYear !== 998) confirmation = confirm(`${this.countCheckFlags()}人の生徒の学年を${this.targetSchoolYear ? `${this.targetSchoolYear}年生` : '未登録'}に変更します`);
      else confirmation = confirm(`${this.countCheckFlags()}人の生徒を非アクティブにします`);

      if (!confirmation) return true; // キャンセルは即時中断

      try {
        const promises = target.map((data) => {
          let params;
          if (this.targetSchoolYear === 998) {
            params = {
              id: data,
              flag: 998,
            };
          } else {
            params = {
              id: data,
              school_year: this.targetSchoolYear,
            };
          }
          return this.axios({
            method: 'POST',
            url: '/v1/user/set/update',
            params,
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              if (error.message) console.log(error.message);
              else console.log(error);
              alert('学年の更新に失敗しました');
            });
        });
        // 全ての非同期操作が完了してからモーダルを閉じる
        await Promise.all(promises);
        alert(`${this.countCheckFlags()}人の情報を更新しました`);
        this.hideModal();
      } catch {
        alert('学年の更新に失敗しました');
        this.hideModal();
      }
    },

    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.batch_chenge_school_year {
  display: flex;
  &_buttons {
    margin-left: auto;
  }
}
</style>
