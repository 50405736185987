<template>
  <div>
    <spacer :y="6" />
    <contents-box title="グループ" icon="<i class='fa-regular fa-user-group'></i>">
      <loader-simple :flag="flag.loader.progress">
        <div
          :class="$style.school_select"
          v-if="!flag.loader.progress">
          <select
            :class="$style.school_select_box"
            name="school"
            id="selectschool"
            @change="getGroupList()"
            v-model="school">
            <option :value="0">選択してください</option>
            <option
              v-for="school in schools"
              :key="school"
              :value="school">{{school.name}}</option>
          </select>
          <p :class="$style.assistance">グループを表示する学校を選択してください</p>
        </div>

        <div v-if="school">
          <spacer :y="5" />
          <contents-box type="inner">
            <div :class="$style.add_group">
              <basic-btn tag="button" @click="appendGroup(0)">新規追加</basic-btn>
            </div>
            <div v-if="flag.append">
              <spacer :y="5" />
              <unit-editor
                :user="user"
                :school="school"
                :users="users"
                :registFlag="0"
                @pushCancel="appendGroup(0)"
                @registGroup="getGroupList()" />
            </div>
          </contents-box>
          <spacer :y="2" />
          <div
            v-if="displayGroups.length"
            :class="$style.bulk_edit"
          >
            <basic-btn
              tag="button"
              @click="bulkEdit"
            >所属一括編集</basic-btn>
          </div>
          <loader-simple v-if="!flag.noGroup" :flag="flag.loader.group">
            <spacer :y="3" />
            <ul v-if="!flag.loader.group">
              <li v-for="group in displayGroups" :key="group">
                <group-list
                  :flag="flag"
                  :groups="groups"
                  :group="group"
                  :users="users"
                  :user="user"
                  :school="school"
                  @getGroupList="getGroupList" />
              </li>
            </ul>
          </loader-simple>
        </div>
      </loader-simple>
    </contents-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import BasicBtn from '@/views/components/BasicBtn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import UnitEditor from '@/views/components/UnitEditor';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import GroupList from '@/views/components/GroupList.vue';

export default {
  name: 'operator-units',
  components: {
    BasicBtn,
    ContentsBox,
    Spacer,
    UnitEditor,
    LoaderSimple,
    GroupList,
  },
  data() {
    return {
      schools: [],
      school: 0,
      users: [],
      groups: [],
      groupData: {
        label: null,
        name: null,
      },
      flag: {
        append: false,
        noGroup: false,
        loader: {
          progress: true,
          group: true,
          user: true,
        },
      },
    };
  },
  created() {
    this.getSchools();
  },
  computed: {
    ...mapState(['user', 'helper']),
    displayGroups() {
      return this.groups.filter((group) => group.flag === 1); // アクティブなグループのみ表示
    },
  },
  methods: {
    // キャンセルボタン
    appendGroup(cancel = 0) {
      if (cancel) {
        this.clearGroupDatas();
      } else {
        this.flag.append = !this.flag.append;
      }
    },

    // グループ登録モーダル閉じ
    clearGroupDatas() {
      this.groupData.label = null;
      this.groupData.name = null;
      this.flag.append = false;
    },

    // 編集フラグ更新
    toggleGroupFlag(id) {
      const target = this.groups.find((group) => group.id === id);
      if (!target.detailOpen) {
        target.detailOpen = true;
        this.getGroupDetail(target.id);
      } else if (target.editFlag) {
        target.detailOpen = !target.detailOpen;
        target.editFlag = false;
      } else {
        target.detailOpen = !target.detailOpen;
      }
    },

    /** 学校一覧取得 */
    getSchools() {
      // this.showLoading();
      const params = {
        isUsers: 0,
        isAddress: 0,
        isMainUser: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.schools = res.data.data;
          this.flag.loader.progress = false;
        })
        .catch((error) => {
          if (error.message) console.log(error.massage);
          else console.log(error);
          this.flag.loader.progress = false;
        });
    },

    /** dbから該当するグループを取得 */
    getGroupList(updateGroupId = 0) {
      if (updateGroupId) this.flag.loader.user = true;
      else this.flag.loader.group = true;
      const params = { school_id: this.school.id };

      this.groups = [];

      this.appendGroup(1);
      this.getUserList();

      this.axios({
        method: 'GET',
        url: '/v1/unit/get/list',
        params,
      })
        .then((response) => {
          if (response.data.units.data.length) {
            this.groups = response.data.units.data;
            this.flag.loader.group = false;
            this.flag.loader.user = false;
            this.flag.noGroup = false;
          } else { // グループが学校に登録してなかった時
            this.flag.loader.group = false;
            this.flag.noGroup = true;
          }
          // 再度取得したグループ情報にはアコーディオンの開閉フラグがないため閉じてしまうので、アコーディオンを開く
          if (updateGroupId) {
            this.toggleGroupFlag(updateGroupId);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** グループ単体取得ではグループに属するユーザー詳細も取得 */
    getGroupDetail(id) {
      const target = this.groups.find((group) => group.id === id);
      const params = { id };
      this.axios({
        method: 'GET',
        url: '/v1/unit/get/detail',
        params,
      })
        .then((response) => {
          target.userList = response.data.data.userList;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** ユーザーを紐づけるためにユーザーのデータも取得 */
    // 仮登録ユーザーも取得する？
    getUserList() {
      this.users = [];
      const params = { school_id: this.school.id };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          if (response.data.users.data.length) {
            const users = response.data.users.data;
            users.forEach((user) => { // 空白を除去
              user.username = user.username.replace(/"/g, ''); // csvからの登録では文字列リテラルがついていることがあるので
              if (user.username.indexOf(' ')) {
                user.username = user.username.replace(' ', '');
              } else if (user.username.indexOf('　')) {
                user.username = user.username.replace('　', '');
              }
              if (user.account_type === 1 && user.flag < 998) {
                this.users.push(user);
              }
            });
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** 登録 */
    registGroup() {
      if (!this.groupData.label) {
        alert('グループ名を入力してください');
      }
      const confirmation = confirm(`グループ名${this.groupData.label}を登録します。`);
      if (!confirmation) return true;
      const data = {
        school_id: this.user.school[0].id,
        label: this.groupData.label,
        name: this.groupData.name,
        create_user_id: this.user.id,
        update_user_id: this.user.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/unit/set/register',
        data,
      })
        .then((response) => {
          if (response.data.id) {
            alert('グループの登録が完了しました');
            this.clearGroupDatas();
            this.getGroupList(); // 更新後すぐに反映してほしいためグループ取得
          }
          if (response.data.exists) {
            alert('グループは既に登録済みです');
            this.groupData.label = null;
            this.groupData.name = null;
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    bulkEdit() {
      const data = cloneDeep(this.user);
      data._targetSchool = this.school; // 管理者の場合はuserデータに学校情報がないため、追加でセット
      const args = {
        modalName: 'bulk-edit-unit-belongs',
        data,
        callback: {
          from_units_after_update: this.getGroupList, // 所属更新後にグループ一覧を再取得
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.school_select {
  &_box {
    padding: 13px;
    border: none;
    border-radius: 8px;
    background-color: var(--gray-sub);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
.add_group {
  display: flex;
  justify-content: flex-end;
}
.assistance {
  font-size: 12px;
  margin-top: 5px;
  color: #888;
}
.bulk_edit {
  display: flex;
  justify-content: flex-end;
}
</style>
