import { createRouter, createWebHistory } from 'vue-router';

/** @Global */
import NotFound from '@/views/404.vue';

/** @Common */
import InformationList from '@/views/pages/Common/InformationList.vue';
import InformationDetail from '@/views/pages/Common/InformationDetail.vue';
import CheckReport from '@/views/pages/Common/Report.vue';
import Policy from '@/views/pages/Common/Policy.vue';
import Terms from '@/views/pages/Common/Terms.vue';
import TermsUser from '@/views/pages/Common/TermsUser.vue';
import MessageDetail from '@/views/pages/Common/MessageDetail.vue';
// import Training from '@/views/pages/Common/Training.vue';
// import Training2404 from '@/views/pages/Common/Training2404.vue';
import Training2409 from '@/views/pages/Common/Training2409.vue';
import TrainingSchool from '@/views/pages/Common/TrainingSchool.vue';
import TrainingKDDI from '@/views/pages/Common/TrainingKDDI.vue';
// import TrainingACN from '@/views/pages/Common/TrainingACN.vue';
import TrainingACN2 from '@/views/pages/Common/TrainingACN2.vue';
import CocomoniDetail from '@/views/pages/Common/CocomoniDetail.vue';
import RiskApplicationRefer from '@/views/pages/Common/RiskApplicationRefer.vue';

/** @User */
import User from '@/views/pages/User/Home.vue';
import Settings from '@/views/pages/User/Settings.vue';
import Reserve from '@/views/pages/User/Reserve.vue';
import ReserveSelect from '@/views/pages/User/ReserveSelect.vue';
import ReserveForm from '@/views/pages/User/ReserveForm.vue';
import ReserveDetailOnline from '@/views/pages/User/ReserveDetailOnline.vue';
import ReserveDetailText from '@/views/pages/User/ReserveDetailText.vue';
import ReserveDetailVirtual from '@/views/pages/User/ReserveDetailVirtual.vue';
import TimeLine from '@/views/pages/User/TimeLine.vue';
import Signup from '@/views/pages/User/Signup.vue';
import Signup2 from '@/views/pages/User/Signup2.vue';
import SchoolInformationArchive from '@/views/pages/User/SchoolInformationArchive.vue';
import SchoolInformationDetail from '@/views/pages/User/SchoolInformationDetail.vue';
import LTILogin from '@/views/pages/User/LTILogin.vue';

/** @Teacher */
import Teacher from '@/views/pages/Teacher/Home.vue';
import TeacherHistory from '@/views/pages/Teacher/History.vue';
import TeacherSchool from '@/views/pages/Teacher/School.vue';
import SchoolInformationList from '@/views/pages/Teacher/SchoolInformationList.vue';

/** @Counselor */
import Counselor from '@/views/pages/Counselor/Home.vue';
import CounselorReport from '@/views/pages/Counselor/Report.vue';
import CounselorHistory from '@/views/pages/Counselor/History.vue';
import CounselorUserHistory from '@/views/pages/Counselor/UserHistory.vue';
import CounselorRiskApplicationApply from '@/views/pages/Counselor/RiskApplicationApply.vue';
import CounselorRiskApplicationApproval from '@/views/pages/Counselor/RiskApplicationApproval.vue';

/** @Operator */
import Operator from '@/views/pages/Operator/Home.vue';
import RegistUser from '@/views/pages/Operator/Register/RegistUser.vue';
import RegistSchool from '@/views/pages/Operator/Register/RegistSchool.vue';
// import RegistSchoolUser from '@/views/pages/Operator/Register/RegistSchoolUser.vue';
import RegistCounselor from '@/views/pages/Operator/Register/RegistCounselor.vue';
import RegistOperator from '@/views/pages/Operator/Register/RegistOperator.vue';
import UpdateUser from '@/views/pages/Operator/Updater/UpdateUser.vue';
import UpdateSchool from '@/views/pages/Operator/Updater/UpdateSchool.vue';
import UpdateCounselor from '@/views/pages/Operator/Updater/UpdateCounselor.vue';
import OperatorHistory from '@/views/pages/Operator/History.vue';
import PersonalAnalyticsSertch from '@/views/pages/Operator/Analytics/PersonalAnalyticsSertch.vue';
import AnalyticsGraph from '@/views/pages/Operator/Analytics/AnalyticsGraph.vue';
import AnalyticsTextGraph from '@/views/pages/Operator/Analytics/AnalyticsTextGraph.vue';
import AnalyticsReserveGraph from '@/views/pages/Operator/Analytics/AnalyticsReserveGraph.vue';
import RiskApplicationReview from '@/views/pages/Operator/RiskApplicationReview.vue';

/** @Account */
import Login from '@/views/pages/Common/Login.vue';
import Account from '@/views/pages/Common/Account.vue';
import EditProfile from '@/views/pages/Common/Edit/Profile.vue';
import EditPassword from '@/views/pages/Common/Edit/Password.vue';
import EditEmail from '@/views/pages/Common/Edit/Email.vue';
import EditAddress from '@/views/pages/Common/Edit/Address.vue';
import EditStudent from '@/views/pages/Common/Edit/Student.vue';
import EditSchool from '@/views/pages/Common/Edit/School.vue';
import UpdatedEmail from '@/views/pages/Common/Edit/UpdatedEmail.vue';
import ForgotPassword from '@/views/pages/Common/Account/ForgotPassword.vue';
import ResetPassword from '@/views/pages/Common/Account/ResetPassword.vue';
import AccountCounselor from '@/views/pages/Counselor/Account.vue';

/** @Other */
import Sitemap from '@/views/pages/Sitemap.vue';
import FeatureTest from '@/views/pages/FeatureTest.vue';

const routes = [
  /** @Account */
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'アカウント',
    },
  },
  /** @Account */
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'ログイン',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'パスワード再発行',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'パスワード再設定',
    },
  },
  /** @Edit */
  {
    path: '/edit/email',
    name: 'edit-email',
    component: EditEmail,
    meta: {
      title: 'メールアドレス更新',
    },
  },
  {
    path: '/edit/email/updated',
    name: 'updated-email',
    component: UpdatedEmail,
    meta: {
      title: 'メールアドレス更新完了',
    },
  },
  {
    path: '/edit/profile',
    name: 'edit-profile',
    component: EditProfile,
    meta: {
      title: 'プロフィール編集',
    },
  },
  {
    path: '/edit/password',
    name: 'edit-password',
    component: EditPassword,
    meta: {
      title: 'パスワード変更',
    },
  },
  {
    path: '/edit/address',
    name: 'edit-address',
    component: EditAddress,
    meta: {
      title: '住所更新',
    },
  },
  {
    path: '/edit/student',
    name: 'edit-student',
    component: EditStudent,
    meta: {
      title: '生徒情報更新',
    },
  },
  {
    path: '/edit/school',
    name: 'edit-school',
    component: EditSchool,
    meta: {
      title: '所属組織情報更新',
    },
  },
  /** @User */
  {
    path: '/',
    name: 'UserHome',
    component: User,
  },
  {
    path: '/settings',
    name: 'UserSettings',
    component: Settings,
    meta: {
      title: '利用者情報設定',
    },
  },
  {
    path: '/reserve',
    name: 'UserReserve',
    component: Reserve,
    meta: {
      title: 'サービス',
    },
  },
  {
    path: '/reserve/select',
    name: 'UserReserveSelect',
    component: ReserveSelect,
    meta: {
      title: '相談する先生を選ぶ',
    },
  },
  {
    path: '/reserve/select/:type',
    name: 'UserReserveSelectAsType',
    component: ReserveSelect,
    meta: {
      title: '相談する先生を選ぶ',
    },
  },
  {
    path: '/reserve/text',
    name: 'UserReserveText',
    component: ReserveForm,
    meta: {
      title: 'テキスト健康相談',
    },
  },
  {
    path: '/reserve/online/',
    name: 'UserReserveOnline',
    component: ReserveForm,
    meta: {
      title: '予約情報入力',
    },
  },
  {
    path: '/reserve/virtual/',
    name: 'UserReserveVirtual',
    component: ReserveForm,
    meta: {
      title: '予約情報入力',
    },
  },
  {
    path: '/text/:slug',
    name: 'UserMessageDetail',
    component: MessageDetail,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/reserve/online/:slug',
    name: 'UserReserveDetailOnline',
    component: ReserveDetailOnline,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/reserve/virtual/:slug',
    name: 'UserReserveDetailVirtual',
    component: ReserveDetailVirtual,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/cocomoni/:id',
    name: 'CocomoniDetail',
    component: CocomoniDetail,
    meta: {
      title: 'ココモニ詳細',
    },
  },
  {
    path: '/timeline',
    name: 'TimeLine',
    component: TimeLine,
  },
  {
    path: '/signup/',
    name: 'Signup',
    component: Signup,
    meta: {
      title: '利用者メールアドレス登録',
    },
  },
  {
    path: '/signup2/',
    name: 'Signup2',
    component: Signup2,
    meta: {
      title: '利用者情報登録',
    },
  },
  {
    path: '/information/',
    name: 'InformationList',
    component: InformationList,
    meta: {
      title: '事務局からのお知らせ',
    },
  },
  {
    path: '/information/:id',
    name: 'InformationDetail',
    component: InformationDetail,
  },
  {
    path: '/schoolInformation/',
    name: 'SchoolInformationArchive',
    component: SchoolInformationArchive,
    meta: {
      title: '学校だより',
    },
  },
  {
    path: '/schoolInformation/:id',
    name: 'SchoolInformationDetail',
    component: SchoolInformationDetail,
  },
  {
    path: '/policy/',
    name: 'Policy',
    component: Policy,
    meta: {
      title: 'プライバシーポリシー',
    },
  },
  {
    path: '/terms/',
    name: 'Terms',
    component: Terms,
    meta: {
      title: '利用規約',
    },
  },
  {
    path: '/terms/user',
    name: 'TermsUser',
    component: TermsUser,
    meta: {
      title: '利用規約',
    },
  },

  // {
  //   // path: '/training/h3ddWNr9U7eD',
  //   // path: '/training/YZkUN5ZVH8Bx',
  //   path: '/training/hx9f8ah95wfU',
  //   name: 'training',
  //   component: Training,
  //   meta: {
  //     title: '研修動画',
  //   },
  // },
  // {
  //   path: '/training/rmCBCtAW9XWw',
  //   name: 'training2404',
  //   component: Training2404,
  //   meta: {
  //     title: '研修動画',
  //   },
  // },
  {
    path: '/training/g5ugZ4NUfvzc',
    name: 'training2409',
    component: Training2409,
    meta: {
      title: '研修動画',
    },
  },
  {
    path: '/training/Pd2NaeKwQSNN',
    name: 'trainingSchool',
    component: TrainingSchool,
    meta: {
      title: '研修動画',
    },
  },
  {
    path: '/training/57HK5RhREyCg',
    name: 'trainingKDDI',
    component: TrainingKDDI,
    meta: {
      title: '研修動画',
    },
  },
  // {
  //   path: '/training/GquthBWrupN6',
  //   name: 'trainingACN',
  //   component: TrainingACN,
  //   meta: {
  //     title: '研修動画',
  //   },
  // },
  {
    path: '/training/YfRxxKCMbyW8',
    name: 'trainingACN2',
    component: TrainingACN2,
    meta: {
      title: '研修動画',
    },
  },

  /** @Teacher */
  {
    path: '/teacher/',
    name: 'Teacher',
    component: Teacher,
    meta: {
      // title: 'Welcome to talk',
    },
  },
  {
    path: '/teacher/school/',
    name: 'TeacherSchool',
    component: TeacherSchool,
    meta: {
      title: '学校情報設定',
    },
  },
  {
    path: '/teacher/report/:slug',
    name: 'TeacherReport',
    component: CheckReport,
    meta: {
      title: '報告書兼意見書',
    },
  },
  {
    path: '/teacher/terms/',
    name: 'TeacherTerms',
    component: Terms,
    meta: {
      title: '利用規約(教育機関向け)',
    },
  },
  {
    path: '/teacher/information/',
    name: 'TeacherInformation',
    component: InformationList,
    meta: {
      title: '事務局からのお知らせ',
    },
  },
  {
    path: '/teacher/schoolInformation/',
    name: 'TeacherSchoolInformation',
    component: SchoolInformationList,
    meta: {
      title: '学校だより',
    },
  },
  {
    path: '/teacher/reserve/online/:slug',
    name: 'TeacherReserveDetailOnline',
    component: ReserveDetailOnline,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/teacher/reserve/virtual/:slug',
    name: 'TeacherReserveDetailVirtual',
    component: ReserveDetailVirtual,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/teacher/information/:id',
    name: 'TeacherInformationDetail',
    component: InformationDetail,
  },
  {
    path: '/teacher/history/:slug',
    name: 'TeacherHistory',
    component: TeacherHistory,
    meta: {
      title: '相談履歴',
    },
  },
  {
    path: '/teacher/text/:slug',
    name: 'TeacherMessageDetail',
    component: MessageDetail,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/teacher/cocomoni/:id',
    name: 'TeacherCocomoniDetail',
    component: CocomoniDetail,
    meta: {
      title: 'ココモニ詳細',
    },
  },
  {
    path: '/teacher/risk-applications/:risk_application_id',
    name: 'TeacherRiskApplicationRefer',
    component: RiskApplicationRefer,
    meta: {
      title: 'リスク報告書',
    },
  },
  {
    path: '/lti',
    name: 'LTILogin',
    component: LTILogin,
  },

  /** @Counselor */
  {
    path: '/counselor/',
    name: 'Counselor',
    component: Counselor,
    // meta: {
    //   title: '',
    // },
  },
  {
    path: '/counselor/information/',
    name: 'CounselorInformation',
    component: InformationList,
    meta: {
      title: '事務局からのお知らせ',
    },
  },
  {
    path: '/counselor/information/:id',
    name: 'CounselorInformationDetail',
    component: InformationDetail,
  },
  {
    path: '/counselor/reserve/text/:slug',
    name: 'CounselorReserveDetailText',
    component: ReserveDetailText,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/counselor/reserve/online/:slug',
    name: 'CounselorReserveDetailOnline',
    component: ReserveDetailOnline,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/counselor/reserve/virtual/:slug',
    name: 'CounselorReserveDetailVirtual',
    component: ReserveDetailVirtual,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/counselor/history/user/:slug',
    name: 'CounselorUserHistory',
    component: CounselorUserHistory,
    meta: {
      title: '相談履歴',
    },
  },
  {
    path: '/counselor/report/:slug',
    name: 'CounselorReport',
    component: CounselorReport,
    meta: {
      title: '報告書作成',
    },
  },
  {
    path: '/counselor/registed-report/:slug',
    name: 'CounselorRegistedReport',
    component: CheckReport,
    meta: {
      title: '報告書兼意見書',
    },
  },
  {
    path: '/counselor/history/:slug',
    name: 'CounselorHistory',
    component: CounselorHistory,
    meta: {
      title: '相談履歴',
    },
  },
  {
    path: '/counselor/account',
    name: 'CounselorAccount',
    component: AccountCounselor,
    meta: {
      title: '医師・心理士アカウント情報',
    },
  },
  {
    path: '/counselor/text/:slug',
    name: 'CounselorMessageDetail',
    component: MessageDetail,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/counselor/cocomoni/:id',
    name: 'CounselorCocomoniDetail',
    component: CocomoniDetail,
    meta: {
      title: 'ココモニ詳細',
    },
  },
  {
    path: '/counselor/risk-applications/apply',
    name: 'CounselorRiskApplicationApply',
    component: CounselorRiskApplicationApply,
    meta: {
      title: 'リスク報告書',
    },
  },
  // TODO 下書きはPath VariableなURIにしたい
  {
    path: '/counselor/risk-applications/:risk_application_id/approval',
    name: 'CounselorRiskApplicationApproval',
    component: CounselorRiskApplicationApproval,
    meta: {
      title: 'リスク報告書',
    },
  },
  {
    path: '/counselor/risk-applications/:risk_application_id',
    name: 'CounselorRiskApplicationRefer',
    component: RiskApplicationRefer,
    meta: {
      title: 'リスク報告書',
    },
  },

  /** @Operator */
  {
    path: '/operator',
    name: 'Operator',
    component: Operator,
    meta: {
      // title: 'Welcome to talk',
    },
  },
  {
    path: '/operator/information/:id',
    name: 'OperatorInformationDetail',
    component: InformationDetail,
  },
  {
    path: '/operator/regist-user/',
    name: 'OperatorRegistUser',
    component: RegistUser,
    meta: {
      title: '利用者情報登録',
    },
  },
  {
    path: '/operator/regist-school/',
    name: 'OperatorRegistSchool',
    component: RegistSchool,
    meta: {
      title: '学校情報登録',
    },
  },
  // {
  //   path: '/operator/regist-schoolUser/',
  //   name: 'OperatorRegistSchoolUSer',
  //   component: RegistSchoolUser,
  //   meta: {
  //     title: '学校管理者個別登録',
  //   },
  // },
  {
    path: '/operator/regist-counselor/',
    name: 'OperatorRegistCounselor',
    component: RegistCounselor,
    meta: {
      title: '医師・心理士情報登録',
    },
  },
  {
    path: '/operator/regist-operator/',
    name: 'OperatorRegistOperator',
    component: RegistOperator,
    meta: {
      title: '事務局管理者情報登録',
    },
  },
  {
    path: '/operator/update-user/:slug',
    name: 'OperatorUpdateUser',
    component: UpdateUser,
    meta: {
      title: '利用者情報更新',
    },
  },
  {
    path: '/operator/update-school/:slug',
    name: 'OperatorUpdateSchool',
    component: UpdateSchool,
    meta: {
      title: '学校情報更新',
    },
  },
  {
    path: '/operator/update-counselor/:slug',
    name: 'OperatorUpdateCounselor',
    component: UpdateCounselor,
    meta: {
      title: '医師・心理士情報更新',
    },
  },
  {
    path: '/operator/report/:slug',
    name: 'OperatorReport',
    component: CheckReport,
    meta: {
      title: '報告書兼意見書',
    },
  },
  {
    path: '/operator/history/:slug',
    name: 'OperatorHistory',
    component: OperatorHistory,
    meta: {
      title: '相談履歴',
    },
  },
  {
    path: '/operator/reserve/online/:slug',
    name: 'OperatorReserveDetailOnline',
    component: ReserveDetailOnline,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/operator/reserve/virtual/:slug',
    name: 'OperatorReserveDetailVirtual',
    component: ReserveDetailVirtual,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/operator/reserve/text/:slug',
    name: 'OperatorReserveDetailText',
    component: ReserveDetailText,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/operator/text/:slug',
    name: 'OperatorMessageDetail',
    component: MessageDetail,
    meta: {
      title: '詳細',
    },
  },
  {
    path: '/operator/cocomoni/:id',
    name: 'OperatorCocomoniDetail',
    component: CocomoniDetail,
    meta: {
      title: 'ココモニ詳細',
    },
  },
  {
    path: '/operator/personalanalyticssertch/:id',
    name: 'OperatorPersonalAnalyticsSertch',
    component: PersonalAnalyticsSertch,
    meta: {
      title: '個人アナリティクス',
    },
  },
  {
    path: '/operator/analytics/text/',
    name: 'OperatorAnalyticsGraph',
    component: AnalyticsGraph,
    meta: {
      title: '共通　アナリティクス',
    },
  },
  {
    path: '/operator/analytics/text/',
    name: 'OperatorAnalyticsTextGraph',
    component: AnalyticsTextGraph,
    meta: {
      title: 'テキスト健康相談　アナリティクス',
    },
  },
  {
    path: '/operator/analytics/text/',
    name: 'OperatorAnalyticsReserveGraph',
    component: AnalyticsReserveGraph,
    meta: {
      title: 'オンライン健康相談　アナリティクス',
    },
  },
  {
    path: '/operator/risk-applications/:risk_application_id/review',
    name: 'RiskApplicationReview',
    component: RiskApplicationReview,
    meta: {
      title: 'リスク報告書',
    },
  },
  {
    path: '/operator/risk-applications/:risk_application_id',
    name: 'OperatorRiskApplicationRefer',
    component: RiskApplicationRefer,
    meta: {
      title: 'リスク報告書',
    },
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: Sitemap,
    meta: {
      title: 'サイトマップ',
    },
  },
  {
    path: '/counselor/featuretest',
    name: 'FeatureTest',
    component: FeatureTest,
    meta: {
      title: 'Featureテストページ',
    },
  },

  /** @notfound */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'お探しのページは見つかりませんでした',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    if (!to.hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
